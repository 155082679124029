<template>
<div>
    <h2>Entrevista Abril - 2022</h2>
    <ul>
        <li><b>Artigo:</b> How Could Information Systems Support Transparency to Face Corruption? Systematic 
        Mapping of the Literature. SBSI 2022. Curitiba, Paraná.</li>
        <li><b>Pesquisadores entrevistados: </b> <a href=" http://lattes.cnpq.br/4540774422689570" target="_blank">
            Prof. Tadeu Moreira de Classe</a> (UNIRIO) e <a href=" http://lattes.cnpq.br/5825446300218282" target="_blank">
            Prof. Henrique Prado de Sá Sousa</a> (UNIRIO).</li>
    </ul>

    <br><br>
    
    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b>Vocês identificaram, no trabalho do
    SBSI 2022, que comunidades como Administração e Saúde parecem mais preocupadas com “Transparência
    e Corrupção” que a comunidade de SI. Como isto pode ser mudado?</p>

    <p><b>Prof. Tadeu</b> – Em nosso artigo (“How Could Information Systems Support Transparency to 
    Face Corruption? Systematic Mapping of the Literature”) identificamos diferentes contextos e 
    comunidades que se preocupam com a busca de alternativas e soluções sobre os temas de transparência
    e como isso pode dar suporte ao combate à corrupção. Neste trabalho identificamos a maior quantidade
    de trabalhos em relação aos temas de saúde, administração e governo digital. O que quero dizer é que
    a multiplicidade de sistemas de informação permite adentrar em outros domínios (administração, saúde
    etc.) e encontrar os  contextos em que as intervenções são usadas. Em nosso trabalho apontamos isso,
    identificamos o contexto em que um SI foi aplicado.</p>
         
    <p><b>Prof. Henrique</b> – A administração, por algumas questões, como regulatórias e de relacionamento,
    já percebe o impacto da transparência e da corrupção há muito tempo, sendo compreensível sua preocupação
    extensiva. A interseção da administração e dos sistemas de informação ocorre de forma efetiva através 
    das organizações. Neste ambiente os sistemas de informação e a computação se tornam ferramentas muito
    relevantes para o devido tratamento da qualidade de transparência que, por sua vez, auxilia no combate
    à corrupção.</p>

    <p><b>Prof. Tadeu</b> – Em nosso trabalho, não nos referimos a Sistemas de Informação como apenas um
    software, como muitos conhecem, mas como um conjunto de componentes que envolvem pessoas executando
    processos organizacionais, consumindo e manipulando e produzindo informações (um sistema :]). Enfim, 
    não acho a comunidade seja mais ou menos preocupadas com o tema de transparência, visto que temos,
    inclusive o desafio “Transparency in Information Systems” dentro dos Grandes Desafios de Sistemas 
    de Informação do Brasil de 2016-2026. Contudo, em se tratando do combate a corrupção, acredito que 
    ainda seja necessário caminhar com cuidado pois, podemos ser mal compreendidos no nosso discurso
    (politicamente falando). Bom, para concluir, não sabemos ainda o que se pode fazer para que a 
    comunidade de SI passe a se interessar ainda mais nesta temática. Acredito que este trabalho 
    escrito por mim, prof. Henrique e o prof. Ronney pode ser um ponto de partida para mudar a situação.
    Quem sabe!,/p>

    <br><br>

    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Quais características um SI precisa
    ter quando se fala em “transparência para combate à corrupção”? </p>

    <p><b>Prof. Tadeu</b> – Boa pergunta! Queria muito ter uma resposta assertiva para essa questão. 
    Na nossa pesquisa tentamos responder a essa questão, mas não significa que não possa haver 
    outras características. Observamos que a grande parte dos trabalhos que analisamos, ao falar sobre
    as temáticas de transparência e corrupção, eram associados ao conceito de accountability e o uso e
    gestão de dados aberto. Portanto, eu responderia essa questão dizendo que um sistema de informação
    que use transparência para dar suporte ao combate à corrupção deveria usar e gerir dados abertos de
    forma confiável, interoperável e descentralizada, permitindo com que seus usuários tenham acesso às
    informações de forma simples, compreensível e confiável, de maneira que, se quiserem, eles possam 
    auditá-las. Ou seja, temos um grande e desafiador caminho pela frente.</p>

    <p><b>Prof. Henrique</b> – O conceito de transparência foi aprofundado através de estudos orientados
    pelo professor Julio Leite no grupo de pesquisa em Engenharia de Requisitos da PUC-Rio [1] gerando 
    um grafo de qualidades que são vinculadas ao conceito de transparência. Inicialmente o estudo foi
    orientado para a construção de um catálogo orientado à transparência aplicada a software. 
    Posteriormente a base do catálogo, replicável por ser mais abstrata, foi aplicada a outros elementos,
    por exemplo, processos e modelos organizacionais. O grafo de transparência nos mostra que a 
    transparência é uma qualidade resultante de outras. Isso significa que a transparência não é 
    alcançada diretamente, mas ampliada à medida em que diversas outras qualidades são agregadas 
    em um elemento. Ampliar a qualidade de transparência significa implementar ações que amplie as
    qualidades vinculadas a ela. Quanto à corrupção, identificar mecanismos de combate é um dos passos
    relevantes para mitigá-la. A transparência da informação é facilmente percebida como algo que 
    dificulta a corrupção, mas a sua aplicação pode não ser simples. A transparência precisa ser 
    posta em prática (como chamamos, operacionalizada) para trazer contribuições que efetivamente tenham
    capacidade de “ferir” os mecanismos de corrupção. Conhecer os pormenores da corrupção é um requisito    
    para pensarmos em mecanismos que modifiquem a estrutura do sistema informacional de uma organização
    para que ele não somente impeça certas movimentações, mas também seja combativa. Não seria absurda a
    analogia entre mecanismos de combate à corrupção em sistemas de informação a mecanismos de segurança
    em softwares que combatem vírus. Não sabemos quanto impacto alcançaremos em nossas pesquisas, mas 
    cremos que é na estrutura de processos e informação que se encontram as vulnerabilidades para a 
    corrupção e onde podemos criar mecanismos para combatê-la. Portanto, um SI precisa ter inúmeras
    qualidades vinculadas à transparência, aplicadas a diversos elementos que a compõem, em pormenores,
    para reduzir as suas vulnerabilidades.<br>
    Obs: para compreender melhor as relações qualitativas que fazemos referência, recomendamos o estudo
    da literatura de requisitos não funcionais, em especial o NFR Framework e os diversos trabalhos que
    dele se desdobram.</p>

    <br><br>

    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> No trabalho de pesquisa, feito por
    vocês, foi possível identificar algum SI já desenvolvido com foco na transparência e no 
    combate a corrupção? </p>

    <p><b>Prof. Tadeu</b> – Se considerarmos SI não apenas como softwares, mas processos de trabalho
    regidos por pessoas que consomem, processam, geram informações e tomam decisões a partir delas, 
    alguns trabalhos foram identificados. Como exemplo, o projeto OPEN na Korea [2], um sistema 
    anticorrupção baseado nas premissas de governo digital e, o projeto Bhoomi na Índia, também 
    dentro do contexto de governo eletrônico, feito para combater corrupção na regulação e registro
    de terras. Em termos de software, um trabalho interessante encontrado foi uma proposta na 
    República Tcheca sobre, criando um índice de transparência e um portal para analisar contratos
    de licitação [3].</p>

    <p><b>Prof. Henrique</b> – É muito importante distanciar o conceito de Sistemas de Informação
    do conceito de Software. Sistemas de Informação é uma estrutura construída para lidar com o volume
    imenso de informações que são consumidas e produzidas de forma intrínseca aos diversos fluxos de
    trabalho que participam do funcionamento de organizações. Softwares são produzidos para apoiar o
    tratamento destas informações ao longo da implementação de processos os quais são apoiados ou 
    parcialmente/completamente automatizados por um software. Caso o contrário, uma organização sem
    software não teria o seu sistema de informação? Na literatura é comum a referência a Sistemas de
    Informação, por exemplo, para áreas como Recursos Humanos, Finanças, Jurídico e outros departamentos.
    Inclusive, são comercializados softwares complexos e maduros para suportar estas áreas, denominados
    como Sistemas de Informação. Cremos que essa confusão ocorre porque o processo organizacional é
    “absorvido” pelo software, e o fluxo de informações pode passar a ser completamente processado por
    ele, se “fundindo”. Observamos que durante a execução de um determinado processo, “n” informações
    podem estar sendo extraídas com interesses diversos, desconexos aos objetivos específicos do processo.
    Por exemplo, dentro de um processo de produção de um determinado produto, é possível extrair 
    informações de desempenho para o RH e de custos para Finanças. Desta forma também é possível 
    monitorar variáveis visando mitigar riscos de corrupção. Cremos que é através de uma visão holística
    destes sistemas, com cruzamentos complexos de dados, que é possível ampliar a possibilidade de se
    obter informações profundas sobre a organização e o contexto em que ela está inserida. Isso já é 
    feito hoje em dia para se alcançar conhecimento estratégico de alto valor para as organizações. É 
    neste nível de organização e complexidade que entendemos ser possível observar os mecanismos da 
    corrupção e utilizar a própria estrutura do SI para combatê-la. Isso pode culminar, por exemplo, 
    na alteração de processos, regras diversas e manipulação de determinados dados. Olhar para a 
    estrutura de SI como recurso de aumento da transparência visando o combate a corrupção é algo que
    se mostrou pouco abordado na literatura, segundo os resultados de nosso estudo. Observamos que existem
    diversos trabalhos visando a transparência de informações através do cruzamento de dados e métodos
    de representação complexos que auxiliem o entendimento e a percepção do que ocorre no mundo real. 
    Podemos apontar como exemplo os trabalhos apresentados em eventos como o Workshop de Transparência 
    em Sistemas (WTRANS) e o Workshop de Computação Aplicada em Governo Eletrônico (WCGE 2022). Observamos
    que muitos trabalhos orientam seus esforços para a estrutura dos dados, seus processamentos e formas
    de apresentação. Atuar na estrutura de SI pode gerar impacto mais profundo porque afeta a origem e o
    processamento “orgânico” das informações, em “tempo de execução”, ou… “tempo de corrupção”?
</p>

    <br><br>

    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b>  Por que o blockchain é relevante
    para os SIs no suporte à transparência de informações e no combate à corrupção?</p>

    <p><b>Prof. Tadeu</b> – Em termos de transparência de informação, não conta o dado estar apenas 
    disponível e aberto para acesso. É preciso que ele seja confiável, ou seja, que quem estiver o
    acessando consiga realmente rastrear a sua origem e atestar a sua veracidade. Neste sentido, vemos
    que tecnologias como blockchain podem trazer benefícios a isso. Recentemente, em 18/04/2022 saiu
    na mídia a notícia de que o TCU e BNDES vão criar a Rede Blockchain Brasil para aumentar a 
    transparência de gastos públicos [4]. E isso vai de encontro com o que eu acabei de comentar, 
    essas instituições federais brasileiras parecem que entenderam que não basta a informação estar
    aberta e disponível para a sociedade, mas ela precisa ser confiável.</p>

    <p><b>Prof. Henrique</b> – Podemos interpretar a estrutura de blockchain como uma operacionalização
    de transparência [5] que é capaz de contribuir para diversas qualidades que estão vinculadas a ela,
    segundo o Catálogo de Transparência, como as qualidades de auditabilidade (ex. controlabilidade,
    verificabilidade e rastreabilidade). Trata-se de uma estrutura composta de mecanismos que não 
    somente implementam estas qualidades, como a fazem em um nível bastante relevante. Ao longo de alguns
    anos de estudo no tema, podemos afirmar que muitas das boas práticas implementadas usualmente nas
    organizações são benéficas por ampliarem qualidades de transparência. Por exemplo, no artigo 
    “Alinhamento de operacionalizações entre Transparência e MPS.BR”, observamos a relação entre o 
    MPS.BR que traz várias boas práticas para um processo de desenvolvimento de software, e os vínculos
    diretos com as qualidades de transparência.</p>

    <br><br>

    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b>Que outras tecnologias, além do blockchain, 
    estão sendo usadas para garantir a transparência das informações?  </p>
    
    <p><b>Prof. Henrique</b> – Basicamente todo recurso tecnológico que torne a informação mais acessível,
    mais informativa, que auxilie no seu entendimento e uso, e permita a sua auditabilidade, são
    operacionalizações de transparência.</p>
    
    <p><b>Prof. Tadeu</b> – Em nosso artigo de pesquisa identificamos algumas tecnologias que, se 
    trabalharem juntas, podem contribuir com a transparência das informações e garantindo que as 
    informações estejam disponíveis online, sejam descentralizadas, interoperáveis em diferentes
    dispositivos e que esses sistemas sejam eficientes. Dentre elas, mas não se limitando a elas, 
    podemos destacar: uso e gestão de bases de dados escaláveis (podendo ou não se distribuídas); 
    acesso às informações por meio de APIs e serviços web, garantindo o acesso por diferentes sistemas; 
    uso de interfaces Web e/ou mobile; georreferenciamento; e outras.</p>

    <br><br>

    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Que medidas a UNIRIO poderia adotar
    nos seus Sistemas de Informação para garantir transparência e ajudar no combate à corrupção? </p>

    <p><b>Prof. Tadeu</b> – Grande pergunta. Acho que no primeiro momento, em termos de software, é 
    investir na melhoria e atualização dos softwares existentes ou a compra de soluções mais modernas. 
    Digo isso pois, a grande parte dos processos executados na UNIRIO ainda são feitos de forma 
    analógica por meio de papel o que dificulta um pouco a auditabilidade e abertura das informações.</p>

    <p><b>Prof. Henrique</b> – Para combater a corrupção cremos ser necessário um nível maduro de
    organização do SI. Isso irá facilitar o seu entendimento e consequentemente a sua administração. 
    Se um SI se encontra desorganizado, este cenário pode favorecer ações corruptivas. O software 
    é um dos principais aliados dos sistemas de informação, entretanto, a qualidade do software é 
    um problema constante. Para se obter melhores resultados, não somente na UNIRIO, mas em qualquer
    organização, se faz necessário evoluir processos e tratar adequadamente suas informações de forma
    a possibilitar o seu uso, habilitando análises tanto no nível gerencial quanto no nível do cidadão.
    Ao cidadão, há de se observar especial cuidado por este possuir direitos de acesso garantido por 
    Lei a determinadas informações. Conforme já mencionado anteriormente, não basta tornar as informações
    acessíveis se elas não estão em um formato que permita o seu entendimento e uso, bem como a sua
    verificação.</p>

    <br><br>

    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Em 2021, nós fizemos dez anos da
    Lei de Acesso à Informação (LAI). Nós estamos conseguindo aplicar essa lei? Como a comunidade
    de SI pode ajudar?</p>

    <p><b>Prof. Henrique</b> – Particularmente, tive alunos que realizaram há um tempo trabalhos
    de auditoria em sites da prefeitura do RJ com resultados insatisfatórios quanto ao respeito à LAI. É
    possível que isso tenha mudado, mas precisaria realizar novas auditorias. As informações obrigatórias 
    poderiam ser bem definidas e padronizadas de uma forma linear, especialmente porque existem Leis
    vinculadas a estas informações. Alguns trabalhos na área de transparência buscam através de acesso a
    bases diversas, extrair, tratar e exibir da melhor forma um conjunto de informações pertinentes. 
    Por que o sistema de informação já não está organizado de forma a prover mais facilmente estas 
    informações ao invés de se criar camadas de software visando suprir a estrutura que se encontra 
    pouco alinhada? Muitas vezes o problema é estrutural e a comunidade cria mecanismos para mitigar
    problemas. Porém o problema pode estar em como o SI está desenhado, ou como os conceitos envolvidos
    estão mapeados. Ou seja, à LAI importa a qualidade final da informação, mas os problemas podem estar
    dispersos, desde a concepção de conceitos, estruturas de bancos de dados, até pessoas que tomam 
    decisões equivocadas (ou…corruptas). A comunidade de SI está continuamente buscando formas de auxiliar
    a transparência de informações, porém cremos que entender os mecanismos de corrupção e quais
    vulnerabilidades nos SIs estão sendo utilizadas, neste momento, é um requisito para se pensar em 
    propostas acertadas. Essa visão orientada ao SI possivelmente nos trará maior entendimento sobre
    os problemas.</p>

    <br><br>

    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Para o aluno em busca de um tema
    de pesquisa que enfoque transparência e corrupção, o que vocês aconselhariam?</p>

    <p><b>Prof. Henrique</b> – Um estudo orientado aos alunos de graduação é a avaliação, através do
    formalismo de uma auditoria, da aderência dos sites voltados à transparência da informação em
    relação às Leis, especialmente os governamentais. Outros estudos podem ser vinculados à apresentação
    da informação, mais próximo ao tema de interface. Isso também pode incluir o aspecto de 
    linguagem/comunicação. Estudos também podem ser realizados conceitualmente avançando sobre o 
    conceito de transparência, bem como o aprofundamento nos estudos dos requisitos não funcionais
    e seus catálogos. O mesmo pode ser feito no contexto da corrupção. Business Intelligence e 
    Inteligência Artificial são tópicos quentes que podem levar a operacionalizações relevantes da
    transparência contra a corrupção.</p>

    <p><b>Prof. Tadeu</b> – Como estamos caminhando nessa temática ainda, acredito que em um primeiro
    momento o interessante seria estudar um pouco de tecnologias voltadas ao blockchain e dados abertos.
    Blockchain em relação a confiabilidade dos dados disponíveis, necessário para uma transparência 
    fetiva. E dados abertos no intuito de conseguir organizar, dar sentido e relacionar os dados, 
    criando assim, informação que faça sentido. Neste último, é possível estudar temas relacionados, 
    inclusive com a Web Semântica, como, por exemplo, RDF, grafos relacionados, dados conectados etc.
    Ainda relacionado a dados abertos, um outro tema que pode ser útil é o Data Mining. Pois em muitos
    portais e sites os dados são disponibilizados de forma textual ou em PDF. A mineração de dados pode
    ser útil aqui para tentar traduzir esses dados para formatos mais simples e eficientes de serem
    processados, além de poder encontrar e deduzir informações que não estejam explícitas. Bom, acho que
    existem diversos assuntos que alunos que se interessem sobre o tema podem seguir. Não só tecnológicos,
    mas questões mais profundas como fazer com que as pessoas entendam os dados, reflitam sobre eles, mudem
    o comportamento e, até mesmo, se tornarem fiscais sociais ativos, passíveis de identificar 
    irregularidades e realizar denúncias a entidades reguladoras. Seria isso um sonho? Talvez, mas se
    quisermos, acredito ser possível.</p>
    
    <br><br>

    <p><b>Referências</b></p>
    <ul type="none">
    <li> [1] <a href="http://transparencia.inf.puc-rio.br/wiki/index.php" target="_blank">
         http://transparencia.inf.puc-rio.br/wiki/index.php</a> </li>
    <li> [2] <a href="https://www.sciencedirect.com/science/article/abs/pii/S0740624X08001172" target="_blank">
         https://www.sciencedirect.com/science/article/abs/pii/S0740624X08001172</a> </li>
    <li> [3] Jana Chvalkovská, Katarína Marková, and Jiří Skuhrovec. 2010. The eGovernment Tools as Democracy 
         Watchdogs: Public e-Procurement in the Czech Republic. In ICEG2006-Proceedings of the 6th International
         Conference on e-Government: ICEG2006. Academic Conferences Limited, Cape Town, 193</li>
    <li> [4] <a href="https://cointelegraph.com.br/news/tcu-and-bndes-will-create-the-brazil-blockchain-network-to-increase-transparency-of-public-spending"
         target="_blank">https://cointelegraph.com.br/news/tcu-and-bndes-will-create-the-brazil-blockchain-network-to-increase-transparency-of-public-spending
          </a></li>         
    <li> [5] <a href="https://www.scielo.br/j/ram/a/5S8gYsWYwZfT8p8GCGSxsFh/abstract/?lang=en" 
         target="_blank">https://www.scielo.br/j/ram/a/5S8gYsWYwZfT8p8GCGSxsFh/abstract/?lang=en </a></li>   
    </ul>

    <br><br><br>
    
    <p><img src="./assets/tadeu.png" style="float:left; margin:10px;"/><b>Tadeu Moreira de Classe</b> é Professor e 
    Pesquisador no Programa de Pós-Graduação de Pós-Graduação em Informática (PPGI) e Bacharelado em Sistemas de 
    Informação (BSI) da Universidade Federal do Estado do Rio de Janeiro (UNIRIO). Doutor em Informática na 
    Universidade Federal do Estado do Rio de Janeiro (UNIRIO / PPGI - 2019) na temática de Jogos Digitais Baseados 
    em Processos de Negócio. Participante do Grupo de Pesquisa Inovação em Ciberdemocracia (CIBERDEM/UNIRIO). 
    estre em Ciência da Computação na Universidade Federal de Juiz de Fora (PGCC / UFJF - 2014). Graduado no Curso 
    Bacharelado em Sistemas de Informações do Centro de Ensino Superior de Juiz de Fora (CES/JF - 2011). Técnico em
    Informática Industrial pelo Colégio Técnico Universitário (CTU/UFJF - 2007). Analista de Sistemas com vários 
    anos de experiência.  </p>

    <br>

    <p><img src="./assets/henrique.png" style="float:left; margin:10px;"/><b>Henrique Prado de Sá Sousa</b> é
    Professor adjunto do Departamento de Informática Aplicada (DIA) da Universidade Federal do Estado do Rio de 
    Janeiro (UNIRIO). Doutor em informática na Pontifícia Universidade Católica do Rio de Janeiro (PUC-RIO), 
    mestre em Engenharia de Software pela Pontifícia Universidade Católica do Rio de Janeiro (PUC-RIO), graduado
    em Sistemas de Informação pela Universidade Federal do Estado do Rio de janeiro (UNIRIO). Tem experiência em 
    projetos de pesquisa na área de Modelagem Organizacional, Engenharia de Software, Banco de dados e Transparência
    de Software. As linhas de pesquisa de interesse são modelagem e alinhamento organizacional, processos de negócio, 
    transparência organizacional, transparência de software, engenharia de software. </p>
</div>

</template>

<script>
    export default {
        name: "Abril22",
        data: () => ({})
    }
</script>

<style lang="scss" scoped>

</style>
