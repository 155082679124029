<template>
<div>
    <h2>Entrevista Janeiro - 2022</h2>
    <ul>
        <li><b>Artigo:</b> <a href="http://www2.sbc.org.br/ce-si/arquivos/GranDSI-BR_Ebook-Final.pdf" target="_blank">
            Information Systems based on (Linked) Open Data: From Openness to InnovationTransparency in Information 
            Systems</a>. Grand Research Challenges in IS in Brazil ‐ 2016‐2026. Chapter 5, Pag.52-61.</li>
        <li><b>Pesquisador entrevistado: </b> <a href="http://lattes.cnpq.br/2562652838103607" target="_blank">
            Prof. Sean Wolfgand Matsui Siqueira</a> (UNIRIO)</li>
    </ul>

    <br><br>
    
    <p><b>Profa. Morganna – </b>Quais os requisitos necessários para classificar dados como abertos?</p>

    <p><b>Prof. Sean</b> – Primeiramente, obrigado pelo interesse no tema! Espero conseguir apoiar trazendo
    informações adicionais que possam ser úteis.</p>

    <p>Basicamente são dois requisitos [1] primordiais: estar disponível sob uma licença aberta e em formato 
    legível por máquina e para download. Por outro lado, também há uma especificação de oito princípios [2]
    que podem ser entendidos como requisitos necessários para classificar dados como abertos: os dados devem
    ser completos, primários (publicados como coletados da fonte, no menor grau de granularidade), recentes, 
    acessíveis, processáveis por máquina, não discriminatório, não proprietário e livre de restrições de licença.</p>

    <p>Sendo mais específico, temos segundo o Open Data Handbook, que os dados são abertos se puderem ser livremente
    acessados, usados, modificados e compartilhados por qualquer pessoa para qualquer finalidade. Os dados abertos
    são definidos pela Open Definition e exigem que os dados sejam:
    <ol type="A">
    <li>Legalmente abertos: isto é, disponíveis sob uma licença aberta (dados) que permite que qualquer pessoa acesse,
        reutilize e redistribua livremente;</li>
    <li>Tecnicamente aberto: ou seja, que os dados estejam disponíveis por não mais do que o custo de reprodução e em
        formato legível por máquina (machine-readable) e para download em massa (bulk). Este formato machine-readable
        é o que permite que os dados possam ser lidos e processados automaticamente pelo computador, tais como CSV, JSON,
        XML, RDF.</li>
    </ol>
    
    <p>Complementarmente, vemos um desdobramento destes requisitos nos oito princípios para dados abertos, que embora 
    sejam definidos para o contexto de dados abertos governamentais, podem ser entendidos também no contexto geral.</p>

    <p>O interessante é que o conceito de "aberto" da Open Definition corresponde ao aberto em relação ao sofware 
    "open source" e tem relação com o "free" ou "libre" de "free software", movimentos que o Departamento de Informática
    da UNIRIO esteve envolvido desde seu início.</p>

    <p><b>Profa. Morganna – </b>O Governo do Brasil é um exemplo na área de dados abertos ou ainda temos um longo 
    caminho a percorrer? </p>

    <p><b>Prof. Sean</b> – O Governo do Brasil vem apresentando uma ascensão nos rankings de dados abertos, porém  
    ainda temos um longo caminho a percorrer. Embora em relação à publicação dos dados tenhamos diversas iniciativas, 
    ainda são poucas iniciativas que exploram o uso dos dados abertos e, portanto, não temos tanto impacto em relação
    a dados abertos.</p>

    <p>É interessante observar esta relação de dados abertos e governo. Quando falamos de dados abertos é comum esta
    associação com dados abertos governamentais. Por que o governo é importante neste sentido? Como o próprio Open Data
    Handbook [3] explica, "tanto por causa da quantidade e centralidade dos dados que coleta, mas também porque a maioria 
    desses dados governamentais são dados públicos por lei e, portanto, podem ser abertos e disponibilizados para uso 
    de outros".</p>

    <p>Em relação ao Governo do Brasil, podemos observar a dedicação dos profissionais envolvidos na abertura dos dados
    mesmo com as adversidades, como a pandemia. O Brasil saiu da 24ª posição mundial em 2013 para a 12ª em 2015 e 8ª 
    posição em 2016, segundo o Global Open Data Index [4], cujo objetivo era rastrear o estado dos dados abertos governamentais.
    Já o Open Data Barometer, na 4ª edição do relatório global de 2017 [5], apresenta o Brasil na 18ª posição mundial, logo 
    abaixo do Uruguai.<p>

    <p>A Open Data Inventory (ODIN) [6] avalia a cobertura e abertura de estatísticas oficiais para identificar as lacunas,
    promover políticas de dados abertos, melhorar o acesso e encorajar o diálogo entre escritórios nacionais de estatísticas
    e os usuários de dados. É possível ver relatórios dos dados abertos estatísticos em diversos indicadores ao longo dos
    últimos anos. Podemos perceber uma melhoria do Brasil saindo da 82ª posição em 2017 e estando na 48ª posição mundial
    (e da 6ª para a 2ª posição na América Latina no mesmo ano).</p>

    <p>Esta disponibilização dos dados de forma aberta em formato processável é importante porque facilita análises como a
    provida pela OECD [7], onde é possível perceber diversos indicadores do Brasil, comparando com outros países, provendo 
    ainda uma análise econômica [8] e uma análise de como é viver no Brasil [9].

    <p>Para analisar a transparência pública em relação aos estados e municípios do Brasil, vale ressaltar a iniciativa
    Escala Brasil Transparente – Avaliação 360º [10], da CGU.</p>

    <p><b>Profa. Morganna – </b> O que é um ecossistema de dados abertos? Pode nos fornecer um exemplo? </p>

    <p><b>Prof. Sean</b> – Um ecossistema de dados abertos contempla as comunidades envolvidas, identificando
    e entendendo quais são os participantes ou atores, quais são suas interações e como isso gera uma organização
    no sistema, como o sistema atinge um estado de equilíbrio e autossuficiência neste contexto de dados abertos.</p>

    <p>Um ecossistema consiste em um conjunto de comunidades que vivem em um determinado ambiente e interagem entre si
    e com o meio ambiente, de modo organizado, com seus componentes promovendo o equilíbrio do ambiente e sendo
    autossuficiente. Deste modo, busca-se levantar quais são estas comunidades, e entender como elas interagem. 
    Também é necessário entender as características de organização, equilíbrio e autossuficiência.</p>

    <p>No contexto de dados abertos, podemos ver duas grandes comunidades interagindo: produtores dos dados abertos e
    consumidores dos dados abertos. Entretanto, este é um cenário muito geral, pois diversos atores participam destas
    comunidades e as interações são bem mais complexas.</p>

    <p>No 5º capítulo [11] do livro de I GranDSI-BR: Grand Research Challenges in Information Systems in Brazil 2016-2026,
    indicamos alguns trabalhos que discutem atores do ecossistema de dados abertos (como o governo, desenvolvedores de
    aplicações, pequenas e médias empresas, startups, sociedade civil, universidades, agências de fomento e investidores)
    e elementos do ecossistema, que também podem ser entendidos como as atividades que vão possibilitar a organização,
    equilíbrio e autossuficiência (como a auditoria de dados, a seleção de conjuntos de dados, a privacidade de dados,
    garantir a alta qualidade dos dados, o acesso a dados, a descoberta a dados, engajamento de usuários de dados, 
    encorajar reuso e avaliação). As interações entre estes atores através das atividades seguem características e 
    egras específicas para promover uma organização deste ecossistema e o equilíbrio (ou autorregulação) e a 
    autossuficiência.</p>

    <p>Entretanto, no capítulo de livro que mencionei, convocamos a comunidade de Sistemas de Informação para se 
    envolver no que denominamos ecossistema de inovação baseado em dados abertos (conectados). Isto vai além de 
    contemplar apenas a produção e uso de dados abertos, mas contemplar dados conectados e avançar no sentido de
    um ecossistema de inovação.</p>

    <p><b>Profa. Morganna – </b> É possível pensar em dados abertos de empresas privadas ou isto não faz sentido? </p>

    <p><b>Prof. Sean</b> – Em nosso capítulo nos Grandes Desafios de Pesquisa em Sistemas de Informação, já indicávamos
    que companhias e a indústria em geral estavam tornando alguns de seus dados abertos e investindo em programas para
    fomentar e explorar seu uso. Esta visão também é compartilhada com outros pesquisadores e profissionais da área [12] que
    explicam que o setor privado "também produz uma enorme quantidade de dados, muitos dos quais estão diretamente ligados
    a regulamentações governamentais", "não por altruísmo ou filantropia, mas porque isso pode realmente melhorar seus
    resultados". Observa-se ainda que "para muitas empresas, a abertura dos dados pode se traduzir em estruturas de 
    governança interna mais eficientes, feedback aprimorado de trabalhadores e funcionários, melhora na rastreabilidade
    de cadeias de suprimentos, responsabilidade com os consumidores finais e melhor entrega de serviços e produtos".</p>

    <p>Quando trazemos uma perspectiva administrativa de promover auditoria dos dados e atividades das empresas (veja por
    exemplo a lei Sarbanes-Oxley [13]) e os princípios de accountability e governança, percebemos a importância da
    transparência. Com isso também trazemos a percepção da ética. Não vou aprofundar aqui nestes conceitos, mas a ideia
    é motivar para a
    percepção de que dados abertos podem ser apenas um dos elementos de uma gestão mais ética. Com estes elementos, as 
    empresas podem não apenas ser mais responsáveis e éticas, mas também trazer uma confiabilidade maior para suas ações. 
    Com seus dados abertos, as empresas podem também promover ações colaborativas e participativas, engajando os membros
    internos, os parceiros de negócios, os clientes e a sociedade em geral. Isto possibilita não apenas um ambiente de 
    estímulo à inovação, mas também uma promoção da relação de pertencimento e identificação. Portanto, não apenas faz 
    sentido pensar em dados abertos de empresas privadas, mas há um enorme potencial de benefícios desta estratégia.</p>


    <p><b>Profa. Morganna – </b> O principal problema de pesquisa quando se fala de dados abertos parece ser como
    conectar dados vindos de diferentes fontes. Existe alguma proposta sendo estudada para resolver isso?</p>

    <p><b>Prof. Sean</b> – Este é um dos diversos problemas de pesquisa em dados abertos. Há propostas sendo estudadas
    para revolver o problema de conectar dados vindos de diferentes fontes, mas ainda há muitos desafios a serem tratados.

    <p>O Open Data Handbook apresenta uma ótima descrição para esta questão da integração de dados abertos [14], explicando
    a importância de combinar dados de diferentes fontes, assegurando que estes conjuntos de dados sejam compatíveis, 
    que a qualidade dos dados seja boa e ressaltando que "o principal objetivo de dados conectados é tornar a integração
    de dados totalmente ou quase totalmente automática". Por isso, enfatizamos os dados conectados (linked data) como 
    algo importante em dados abertos.</p>

    <p>Em uma importante conferência da área de Banco de Dados (VLDB 2018), Renée Miller deu uma palestra sobre a
    integração de dados abertos [15], apresentando um breve histórico de integração de dados, partindo da ideia de 
    federação de dados (décadas de 1980 e 1990), troca e compartilhamento de dados (anos 2000) e a descoberta de 
    dados dirigidos a consultas (anos 2020). Em sua apresentação, a palestrante situa esta questão da integração 
    de dados abertos com base em repositórios com um imenso número de diferentes conjuntos de dados, chamados na
    área de Banco de Dados de "data lakes". Segundo ela, os dados abertos talvez sejam "um dos 'data lakes' mais 
    valiosos sendo criados atualmente".</p>

    <p>Em um tutorial [16] na 13th Reasoning Web International Summer School, em 2017, sobre a integração de dados para
    dados abertos na web, os palestrantes apresentaram os principais desafios na área: 
    <ol type="i">
    <li> onde encontrar os dados abertos?</li>
    <li> a heterogeneidade de baixo nível dos dados (ou seja, os dados não estão prontamente disponíveis 
         em RDF ou como dados conectados);</li>
    <li> as licenças e proveniência dos dados (nem sempre os dados são completamente abertos);</li>
    <li> questões de qualidade; e </li>
    <li> como encontrar os dados (searchability)?</li>
    </ol></p>
        
    <p><b>Profa. Morganna – </b> Em que consiste a análise de qualidade para dados abertos? Esta análise engloba
    a preocupação com fake news? </p>

    <p><b>Prof. Sean</b> – Ao pensar na qualidade de dados, gosto da organização trazida em um artigo [17] da ACM
    Computing Surveys. Das fases e passos comuns de metodologias de qualidade de dados, o principal foco quando
    falamos de dados abertos está na avaliação/medição, cujos passos seriam: análise de dados, análise de 
    requisitos de qualidade de dados, identificação de áreas críticas, modelagem do processo (de produzir ou 
    atualizar dados) e medição da qualidade.</p>

    <p>Ao aprofundar nas dimensões da avaliação de qualidade e tipos de medição, tem um artigo recente [18] muito
    interessante. Nele podemos perceber a diversidade de dimensões de qualidade de dados propostas nos diversos
    frameworks existentes. Também é interessante para observar que muitos tipos de medições não são viáveis no
    contexto de dados abertos, principalmente se considerarmos repositórios com grandes volumes de dados.</p>

    <p>Para completar, o contexto de dados abertos traz questões específicas a partir, por exemplo, dos próprios
    princípios de dados abertos. Então, embora tenhamos propostas de análise de qualidade de dados abertos, ainda
    há muito a avançar neste sentido.</p>

    <p>Sobre a preocupação com fake news, embora a avaliação/medição da qualidade de dados abertos contemple passos
    que eventualmente permitiriam detectar e tratar a má informação, a desinformação e fake news, desconheço uma
    proposta que explore isto de forma explícita. Nesta linha de pesquisa, um trabalho interessante [19], que tive 
    oportunidade de ler, identifica alguns riscos de desinformação associados a dados abertos: fechar os dados, 
    diluir a qualidade dos dados abertos ao introduzir dados com algum viés, ajustar dados abertos, reduzir a 
    qualidade dos dados abertos, tornar o dado difícil de localizar e não adicionar novos conjuntos de dados ou 
    não atualizar os conjuntos de dados existentes. Os autores discutem ainda os impactos destes riscos e como 
    mitiga-los.</p>
        
    <p><b>Profa. Morganna – </b> Para o aluno em busca de um tema de pesquisa em dados abertos, o que você 
    aconselharia? </p>

    <p><b>Prof. Sean</b> – Acredito que dados abertos podem ser explorados de diversas perspectivas: desde o 
    olhar sobre as estruturas de informação e processamento, integração das informações, de desenvolvimento de 
    aplicações, processos de construção de software baseados em dados abertos, tráfego de rede, formas de conectar
    e agregar semântica aos dados, exploração e visualização dos dados, formas de apresentação dos dados, segurança
    dos dados, padronização dos dados, qualidade dos dados, auditoria da informação, governança de dados, inovação
    social, e assim por diante. Trata-se de uma área relevante tanto para o mercado quanto para a pesquisa. No 
    contexto de pesquisa, além de obter uma fundamentação nos principais conceitos, métodos e ferramentas relacionadas,
    sugiro escolher um tema que mais interesse dentro da área de dados abertos e buscar o estado da arte neste 
    tema específico, ou seja, estudar o que os principais trabalhos da literatura científica trazem sobre este tema, 
    e a partir daí, explorar um problema específico com um orientador.</p>
    
    <br><br>
    
    <p><b>Referências</b></p>
    <ul type="none">
    <li> [1] <a href="https://opendatahandbook.org/glossary/en/terms/open-data/" target="_blank">
             https://opendatahandbook.org/glossary/en/terms/open-data/</a> </li>
    <li> [2] <a href="https://opengovdata.io/2014/8-principles" target="_blank">
             https://opengovdata.io/2014/8-principles</a></li>
    <li> [3] <a href="https://opendatahandbook.org/guide/en/why-open-data/" target="_blank">
             https://opendatahandbook.org/guide/en/why-open-data/</a></li>
    <li> [4] <a href="https://index.okfn.org/place/" target="_blank">
             https://index.okfn.org/place/</a><li>
    <li> [5] <a href="https://opendatabarometer.org/4thedition/report/" target="_blank">
             https://opendatabarometer.org/4thedition/report/</a> </li>
    <li> [6] <a href="https://odin.opendatawatch.com/" target="_blank">
             https://odin.opendatawatch.com/</a></li>
    <li> [7] <a href="https://data.oecd.org/brazil.htm" target="_blank"> 
             https://data.oecd.org/brazil.htm</a></li>
    <li> [8] <a href="https://www.oecd.org/economy/brazil-economic-snapshot/" target="_blank">
             https://www.oecd.org/economy/brazil-economic-snapshot/</a></li>
    <li> [9] <a href="https://www.oecdbetterlifeindex.org/countries/brazil/" target="_blank">
             https://www.oecdbetterlifeindex.org/countries/brazil/</a></li>
    <li> [10] <a href="https://mbt.cgu.gov.br/publico/home" target="_blank">
             https://mbt.cgu.gov.br/publico/home</a></li>
    <li> [11] <a href="https://sol.sbc.org.br/livros/index.php/sbc/catalog/view/28/104/254-1" target="_blank">
             https://sol.sbc.org.br/livros/index.php/sbc/catalog/view/28/104/254-1</a></li>
    <li> [12] <a href="https://blogs.worldbank.org/voices/next-frontier-open-data-open-private-sector" target="_blank">
             https://blogs.worldbank.org/voices/next-frontier-open-data-open-private-sector</a></li>
    <li> [13] <a href="https://pt.wikipedia.org/wiki/Lei_Sarbanes-Oxley" target="_blank">
             https://pt.wikipedia.org/wiki/Lei_Sarbanes-Oxley</a></li>
    <li> [14] <a href="https://opendatahandbook.org/glossary/en/terms/data-integration/" target="_blank">
         https://opendatahandbook.org/glossary/en/terms/data-integration/</a></li>
    <li> [15] <a href="https://doi.org/10.14778/3229863.3240491" target="_blank">
         https://doi.org/10.14778/3229863.3240491</a></li>
    <li> [16] <a href="https://epub.wu.ac.at/5805/1/neum-etal-RW2017.pdf" target="_blank">
         https://epub.wu.ac.at/5805/1/neum-etal-RW2017.pdf</a></li>
    <li> [17] <a href="https://doi.org/10.1145/1541880.1541883" target="_blank">
         Methodologies for Data Quality Assessment and Improvement - https://doi.org/10.1145/1541880.1541883</a> </li>
    <li> [18] <a href="https://doi.org/10.1109/ACCESS.2019.2899751" target="_blank">
         An Overview of Data Quality Frameworks - https://doi.org/10.1109/ACCESS.2019.2899751</a></li>
    <li> [19] <a href="https://doi.org/10.1145/3328747" target="_blank">
         Characterizing Disinformation Risk to Open Data in the Post-Truth Era - https://doi.org/10.1145/3328747</a></li>
    </ul>

    <br><br><br>
    
    <p><img src="./assets/sean.png" style="float:left; margin:10px;"/><b>Sean Siqueira</b> possui graduação em Ciências
    da Computação pela Universidade Federal de Goiás (UFG, 1996), mestrado em Informática pela Pontifícia Universidade 
    Católica do Rio de Janeiro (PUC-Rio, 1999) e doutorado em Informática pela Pontifícia Universidade Católica do Rio
    de Janeiro (PUC-Rio, 2005). Fez pós-doutorado na Universidade Federal de Alagoas (UFAL, 2016). Atualmente é professor
    associado da Universidade Federal do Estado do Rio de Janeiro (UNIRIO). Tem experiência na área de Ciência da Computação,
    com ênfase em Ciência da Web, Sistemas de Informação e Informática na Educação. Foi o coordenador do Programa de 
    Pós-Graduação em Informática (PPGI) da UNIRIO de julho/2012 a setembro/2014, foi coordenador dos comitês de programa do
    SBIE 2012, SBIE 2014, SBSI 2015 e SBSI 2021, além de fazer parte do comitê organizador do CBIE 2015, ser um dos 
    editores-chefes da iSYS: Revista Brasileira de Sistemas de Informação (2012-2016) e da RBIE: Revista Brasileira de
    Informática na Educação (2016-2018), membro da Comissão Especial de Informática na Educação (CEIE) (2014-2018, 2021)
    e da Comissão Especial de Sistemas de Informação (CESI) (2015-) (coordenador 2017-18 e vice-coordenador 2018-19), 
    ambas da Sociedade Brasileira de Computação (SBC). </p>
</div>

</template>

<script>
    export default {
        name: "Marco22",
        data: () => ({})
    }
</script>

<style lang="scss" scoped>

</style>
