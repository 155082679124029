<template>
<div>
    <h2>Entrevista Julho - 2022</h2>
    <ul>
        <li><b>Artigo:</b> <a href="https://www.researchgate.net/profile/Renata-Araujo-10/publication/288989401_Designing_Public_Service_Process_Models_for_Understandability/links/568d504508ae78cc05141873/Designing-Public-Service-Process-Models-for-Understandability.pdf" target="_blank">
            Desigining Public Service Process Models for Understandability</a>. </li>
        <li><b>Pesquisador entrevistado: </b> <a href="http://lattes.cnpq.br/3589012014320121" target="_blank">
            Profa. Renata Mendes de Araujo</a> (Universidade Presbiteriana Mackenzie)</li>
    </ul>

    <br><br>
    
    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> O que é Design Thinking e como 
    esse método pode ser utilizado para obter transparência no serviço público?</p>

    <p><b>Prof. Renata</b> – Design Thinking é uma metodologia para o design de produtos e
    serviços bastante utilizada atualmente em diferentes tipos de organizações, inclusive as públicas.
    Ela parte de alguns princípios e os que eu gostaria de destacar são: o de que as soluções (produtos
    e serviços) precisam ser construídas em um processo contínuo e iterativo de ideação, produção e 
    validação; e necessariamente com o envolvimento dos interessados (usuários) nos produtos e serviços,
    aproximando as equipes técnicas dos usuários finais em um processo de entendimento mútuo e empatia. 
    Entendo que o Design Thinking é importante para a questão da transparência no serviço público porque
    uma das questões fundamentais atualmente para a concepção e oferta de serviços públicos é a empatia 
    e a necessidade de compreender a realidade e necessidades do cidadão, o que é comumente chamado de
    "centrado no cidadão". Isso é uma mudança de mentalidade muito significativa para os servidores 
    públicos, incluindo as equipes de desenvolvimento de soluções digitais. No que se refere à 
    transparência, é importante lembrar que os mecanismos de transparência ativa ou passiva passam 
    pela disponibilização, interação e uso de informação pelo cidadão e pela comunicação do cidadão 
    com a instituição pública, via serviços digitais de informação. Design Thinking pode ser uma 
    abordagem muito importante para pensar junto aos cidadãos em um processo de co-desenho, o que é
    ou não importante ser disponibilizado e de que forma, bem como conscientizar os cidadãos das 
    restrições (legais, operacionais ou culturais) que muitas vezes dificultam a transparência por
    parte das instituições. O Design Thinking é um caminho de aproximação e esta aproximação é 
    fundamental para a transparência efetiva.</p> 


    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Explique mineração de processo 
    (Process mining) e como ela pode ser usada para aumentar a transparência no serviço público?</p>

    <p><b>Prof. Renata</b> – A mineração de processos é uma área de pesquisa e prática que
    reúne conhecimentos das áreas de gestão de processos de negócio (BPM) e inteligência artificial. 
    Em linhas muito gerais, a mineração de processos é a aplicação de técnicas de mineração a registros
    (logs) de execução de processos de negócio em organizações. Esses registros contêm dados das 
    atividades executadas, por quem, quando, por quanto tempo etc e também quais os caminhos ou ordem
    de atividades executadas. Usando ferramentas de mineração de processos é possível analisar esses
    registros e extrair conhecimento sobre o desempenho do processo, fluxo real de sua execução, 
    gargalos, inconsistências em relação a regras etc. É um tremendo mecanismo de gestão de processos
    para melhoria e auditoria. O resultado dessas análises é potencialmente importante para a 
    transparência pois coloca visível dados não conhecidos sobre o desempenho do processo. Pode ser
    possível então disponibilizar esses dados ao público, para o acompanhamento e controle por parte
    do cidadão. O desafio, no entanto, é que essas informações são ainda muito técnicas e um trabalho
    importante aqui é como torná-las fáceis de entendimento pelo cidadão comum.</p> 
    
      
    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Fale um pouco do modelo de
    accountability proposto na sua pesquisa para o setor cultural. Esse modelo poderia ser aplicado
    em outras áreas?</p> 

    <p><b>Prof. Renata</b> – O modelo de accountability foi desenvolvido no escopo de uma
    dissertação de mestrado que tinha como objetivo dar visibilidade a informação sobre projetos 
    e financiamento no setor de cultura no RJ. As políticas públicas culturais à época colocavam
    as OSCIPS (terceiro setor) como atores importantes no processo intermediação do governo federal
    com os atores do setor cultural para o desenvolvimento de projetos. No entanto, informações 
    sobre as OSCIPs e suas atividades eram muito opacas ou inexistentes. A proposta da pesquisa foi
    construir uma solução que fosse capaz de buscar essas informações na web, eventualmente 
    disponibilizadas pelas OSCIPs e organizá-las em um portal para navegação do cidadão comum. 
    Era um momento econômico e político no país onde era possível se pensar em pessoas comuns com 
    interesse em financiar projetos culturais, por exemplo. Onde encontrar essa informação? Para extrair
    os dados, a pesquisa precisou definir um modelo com os itens de informação que eram requiridos pela
    legislação vigente. Tivemos inspirações também de outros modelos encontradas na literatura. Creio 
    que o modelo não possa ser aplicado em outras áreas sem adaptações para o contexto específico que
    se deseja aplicar.</p> 
    
    
    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Um dos seus projetos propõe o 
    uso de jogos para promover a transparência do serviço público. Pode explicar essa ideia?</p> 

    <p><b>Prof. Renata</b> – A proposta é construir jogos que permitam que o cidadão possa jogar, 
    como um jogo de aventura, um processo de prestação de serviço público. O jogador assume o papel de
    um ator do processo (servidor público) e precisa passar por todas as etapas do processo para prestar
    o serviço no jogo. Desta forma, o jogador é capaz de entender o funcionamento do processo dentro da
    organização pública, inclusive suas regras, restrições, situações do cotidiano etc. Nossa expectativa
    é que, tornando esse processo lúdico, possamos promover empatia do cidadão sobre o processo, o 
    servidor público etc, bem como dar transparência sobre como funciona a prestação do serviço.</p> 
    
    
    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Cada vez mais dados públicos
    são disponibilizados para o cidadão. Entretanto, isto parece não aumentar a compreensão do 
    funcionamento da máquina pública. Por que isso ocorre? O que pode ser feito? </p>

    <p><b>Prof. Renata</b> – Há muitos aspectos aqui. O formato dos dados disponibilizados ainda
    não é consumível pelo cidadão típico brasileiro. Apesar dos muitos avanços, nem todas as instituições
    públicas possuem uma política consistente para disponibilização de dados e para transparência. Houve
    um mal entendimento da LGPD como um impedimento à transparência, o que não é absolutamente verdade, 
    as duas normativas podem e devem coexistir. A "máquina pública" é complexa, muito fragmentada e 
    impactada pela visão ideológica preponderante dos governos. Apesar dos avanços como a Lei do Governo
    Digital, temos visto um distanciamento significativo de políticas públicas voltadas a que o cidadão
    tenha essa compreensão. Ainda vivemos sob uma forte cultura de distanciamento entre cidadão e 
    instituições públicas, marcadas por dificuldade de diálogo e desconfiança. O que pode ser feito é 
    continuar investindo em políticas, projetos governamentais, pesquisas e inovação que tragam
    alternativas para aumentar essa compreensão e, sempre e principalmente, conscientização e educação 
    sobre o que é ser cidadão e o que é ser servidor público (em todos os níveis). </p> 
    
    
    <p style="background-color:#EAEAEA"><b>Profa. Morganna – </b> Qual conselho pode ser dado a um
    aluno que busca um tema de pesquisa em transparência? </p>

    <p><b>Prof. Renata</b> – Primeiro de tudo, ele precisa acreditar que esse é um tema importante 
    e precisa se sentir implicado com a ideia de passar um tempo estudando, propondo soluções e gerando
    conhecimento para contribuir com este tema. Depois, é importante buscar um espaço onde este tema é
    estudado e compreender os projetos e iniciativas de pesquisa em andamento. É sempre bom também entrar
    em contato com os pesquisadores do tema e conversar sobre possibilidades de projetos. Por fim, precisa
    amar o Brasil.</p> 
        
    <br><br><br>
    
    <p><img src="./assets/renata.png" style="float:left; margin:10px;"/><b>Renata Mendes de Araujo</b> 
    é professora na Faculdade de Computação e Informática e pesquisadora do Programa de Pós-Graduação
    em Computação Aplicada da Universidade Presbiteriana Mackenzie. Representante de Inovação e
    Empreendedorismo da Faculdade de Computação e Informática na Coordenadoria de Desenvolvimento e 
    Inovação da Universidade Presbiteriana Mackenzie. Pesquisadora do Programa de Pós-Graduação em 
    Sistemas de Informação da EACH-USP. Pesquisadora convidada do LUDES-Laboratório de Ludologia, 
    Engenharia e Simulação da COPPE/UFRJ. Possui graduação em Informática pela Universidade Federal 
    do Rio de Janeiro (1992), mestrado (1994) e doutorado (2000) em Engenharia de Sistemas e Computação
    pela Universidade Federal do Rio de Janeiro. Tem experiência de pesquisa, desenvolvimento tecnológico
    e inovação na área de Computação, com ênfase em Sistemas de Informação, atuando principalmente nos
    seguintes temas: governo e democracia digital, gestão da inovação, gestão de processos de negócio e
    sistemas colaborativos. Coordena o Grupo de Pesquisa e Inovação em Ciberdemocracia (CIBERDEM)
    (ciberdemack.com.br). Tem atuado continuamente na consolidação da comunidade de ensino e pesquisa
    em Sistemas de Informação no Brasil, em particular junto à Sociedade Brasileira de Computação (SBC). 
    Ocupou a Diretoria de Educação da Sociedade Brasileira de Computação (2017-2019). 
    </p>
</div>

</template>

<script>
    export default {
        name: "Julho22",
        data: () => ({})
    }
</script>

<style lang="scss" scoped>

</style>
