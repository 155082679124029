<template>
<div>
    <h2>Entrevista Fevereiro - 2022</h2>
    <ul>
        <li><b>Artigo:</b> <a href="https://alacip.org/cong19/185-dutt-19.pdf" target="_blank">ORÇAMENTO POPULAR: 
            TRANSPARÊNCIA, ENGAJAMENTO E TECNOLOGIA. X Congresso Latino-americano de Ciência Política (ALACIP). 
            Julho 2019.</a>
        </li>
        <li><b>Pesquisador entrevistado: </b> <a href="http://lattes.cnpq.br/2970110332151340" target="_blank">
               Prof. Steven Dutt-Ross</a> (UNIRIO)</li>
    </ul>

    <br><br>

    <p><b>Profa. Morganna – </b> O que é transparência orçamentária? O que se ganha com ela?</p>
    
    <p><b>Prof. Steven Dutt-Ross – </b> Acredito que existe um direito básico dos cidadãos e das cidadãs de conhecerem,
    de serem informados(as) e exercerem o controle social sobre o que as instituições públicas estão fazendo e por quê. 
    Assim, a transparência orçamentária cumpre esse papel de comunicar a forma e o volume com os quais os recursos públicos
    são obtidos e alocados para todos(as). Além disso, a transparência permite checar se os recursos públicos estão sendo
    utilizados como deveriam.</p>
    
    <p><b>Profa. Morganna – </b> Existe transparência orçamentária na UNIRIO?</p>
    
    <p><b>Prof. Steven Dutt-Ross – </b> É difícil responder simplesmente sim ou não a essa pergunta. Existe alguma
    transparência orçamentária em relação ao planejado, como por exemplo, a discussão do Quadro de Detalhamento da 
    Despesa - QDD, feita todos os anos pelo conselho superior da universidade. Todavia, ainda existe pouca prestação
    de contas sobre a execução orçamentária da Universidade. Posso dar dois exemplos: 1) não há o cruzamento do QDD
    com a execução, e 2) não há o detalhamento da execução das emendas parlamentares. Ainda existe muito espaço para a
    universidade melhorar nesse quesito.</p>
    
    <p><b>Profa. Morganna – </b> Existe algum exemplo de transparência em uma outra universidade pública que a UNIRIO
    poderia seguir?</p>
    
    <p><b>Prof. Steven Dutt-Ross – </b> Já fiz uma pesquisa sobre os processos de transparência orçamentária das outras
    universidades. As publicações dos orçamentos da <b>UnB</b> <a  href="http://www.dpo.unb.br/index.php?option=com_content&view=article&id=37&Itemid=876" target="_blank">(http://www.dpo.unb.br/index.php?option=com_content&view=article&id=37&Itemid=876)</a>
    e da USP (<a href="https://sites.usp.br/codage/gestao-orcamentaria/prestacao-de-contas/" target="_blank">
    https:sites.usp.br/codage/gestao-orcamentaria/prestacao-de-contas/</a>)
    são as melhores que encontrei. A UnB disponibiliza de
    forma detalhada cada uma das emendas parlamentares. Apresenta os recursos por deputado, por responsável pela execução,
    e por valor da emenda. Já a USP, faz todo ano um relatório detalhado de execução orçamentária que é muito interessante
    para a comunidade universitária.</p>
    
    <p><b>Profa. Morganna – </b> Como a Estatística pode ajudar na avaliação dos orçamentos?</p>

    <p><b>Prof. Steven Dutt-Ross – </b> Na opinião de Shaughnessy (1992, 1996 apud Lopes 2008), entender estatística é
    essencial aos cidadãos das sociedades atuais: para ser crítico em relação à informação disponível na sociedade, para
    entender e comunicar com base nessa informação, uma vez que uma grande parte da organização dessas mesmas sociedades
    tem por base esses conhecimentos. A habilidade estatística e o entendimento de conceitos como o desvio-padrão e a
    variância, possibilitam a investigação do processo orçamentário por um olhar convergente com a transparência. Desse 
    modo, estatística contribui para o estudo do orçamento por meio da análise da distribuição das despesas orçamentárias,
    bem como, a variabilidade dessas despesas.</p>

    <p>Um outro ponto interessante seria fazer o uso da estatística voltado para a discussão das estratégias efetivas dos
    administradores públicos. Simplificando bastante, as despesas orçamentárias podem ser consideradas como a tradução em
    recursos públicos das prioridades dos gestores. Assim, para além dos discursos, podemos utilizar a estatística a fim
    entender melhor essas ações.  Tem um dito popular que se encaixa aqui: Falar é uma coisa; fazer é outra. A estatística
    permite o mapeamento do que está sendo feito pelo Estado. Essa é a minha opinião.</p>    
         
    <p><b>Profa. Morganna – </b> Qual a importância de usar dados abertos no orçamento da UNIRIO? O que falta para 
    termos dados abertos no orçamento?</p>
    
    <p><b>Prof. Steven Dutt-Ross – </b> Com os dados abertos, a comunidade da UNIRIO  poderá se sentir segura e confiante
    ao saber a finalidade destinada ao Orçamento da Universidade. Por exemplo, o fato de publicar mensalmente informações, 
    em Txt, Pdf, ou Csv, relacionadas com gastos oriundos de despesas com licitações, facilitaria o acesso da comunidade 
    às informações orçamentárias. Isso pode gerar resultados inesperados.  Um resultado que eu gosto muito foi de uma 
    estudante da UFPR. Ao pesquisar o portal da transparência, a estudante passou a perceber pontos fora da curva, ou seja,
    dados que escapavam do padrão. Com isso, ela  descobriu depósitos de valores, bem acima da média, destinado a um pequeno
    grupo de pessoas. Assim, ela detectou sozinha um desvio milionário de bolsas que a própria  UFPR não viu.</p>
    
    <p>Com relação a segunda pergunta sobre o que falta para termos dados abertos, na minha opinião, falta uma cultura de
    disponibilizar de forma transparente os dados sobre isso. Podemos dizer que não falta tecnologia para isso. Eu mesmo,
    consigo coletar dados da execução orçamentária e disponibilizar isso para todos(as) alunos(as) técnicos(as) e
    professores(as). É também deixo disponível no site 
    <a href="http://repositorio.metodosquantitativos.com/tesouro/index.html" target="_blank">http://repositorio.metodosquantitativos.com/tesouro/index.html</a>.
    Além disso, a Unirio poderia publicar todos os contratos assinados em pdf para a consulta de toda comunidade.</p>
    
    <p><b>Profa. Morganna – </b> A transparência orçamentária é uma questão política? Isto é bom ou é ruim? </p>
    
    <p><b>Prof. Steven Dutt-Ross – </b> Lembro de uma experiência no fim do século passado (1998 ou 1999) de uma cidade,
    onde a internet ainda não era tão comum como é hoje. Na entrada da cidade, tinha uma placa mostrando o quanto foram
    os gastos da prefeitura naquele mês e no mês anterior. Havia uma cultura de prestação de contas para a sociedade. 
    Acredito que isso não é só uma questão política, mas sim uma questão de cultura organizacional, mesmo sem internet 
    e tecnologia, uma prefeitura do interior fazia a prestação de contas e a transparência dos seus recursos. Precisamos
    de uma cultura de prestação de contas para a sociedade.</p>
    
    <p><b>Profa. Morganna – </b> O orçamento parece uma sopa de letrinhas que é entendido apenas por um grupo pequeno
    de pessoas na UNIRIO. Como podemos mudar isso?</p>
    
    <p><b>Prof. Steven Dutt-Ross – </b> Aqui acredito que existem dois fatores que considero decisivos: Em primeiro lugar,
    a gestão pública deve facilitar o acesso da comunidade ao orçamento. Nesse sentido, uma ação possível seria criar
    visualizações de dados do orçamento. Uma outra ação poderia ser a criação de cursos sobre o processo orçamentário. 
    Em segundo, é necessário ter um interesse pelo orçamento público. Isto é, uma cultura de acompanhar os gastos públicos.</p>
    
    <p>Claro que devemos sempre buscar a simplicidade, ou seja, o desenvolvimento da percepção e do pleno entendimento, da
    comunidade, acerca dos mecanismos do processo orçamentário como um todo. Regras claras e simples são mais compreensíveis
    e permitem facilitar o acesso e o acompanhamento do uso dos recursos públicos, possibilitando vigiar e ponderar sobre
    as ações da Universidade de uma forma mais exigente.</p>
    
    <p>Além disso, dentro de uma universidade, acredito que a transparência orçamentária  está cada vez mais relacionada
    com o conceito que o prof. Boaventura de Sousa Santos chama de ecologia de saberes, que combina o arcabouço científico
    (sopa de letrinhas) com o conhecimento popular (prioridades de  um grupo amplo dos atores envolvidos no processo da
    UNIRIO).</p>
    
    <p><b>Profa. Morganna – </b>É possível criar uma linguagem mais amigável? Quem na UNIRIO poderia assumir este projeto?</p>

    <p><b>Prof. Steven Dutt-Ross – </b> Já existem várias abordagens para compreender o orçamento para além do jargão. 
    Entre elas, podemos ver a atuação do Fórum Popular do Orçamento do Rio de Janeiro – FPO e do IBASE. Eles já 
    desenvolveram várias iniciativas para o entendimento acerca do orçamento. Vou citar aqui duas delas: 1) a Cartilha
    “De Olho no Orçamento” e a Pesquisa de Prioridades do Cidadão 2) Prefeito por um dia do IBASE. Finalmente, aqui na 
    UNIRIO, já conheci cinco iniciativas recentes que buscam discutir o Orçamento Público da Universidade. Isso indica
    um maior controle social sobre o Orçamento. Vou listá-las a seguir:
    <ol>
    <li>O Projeto Minha Universidade (<a href="https://minhauniversidade.ong.br/">https://minhauniversidade.ong.br/</a>) 
        da profa Morganna Carmem Diniz da Escola de
    Informática Aplicada (EIA);</li>
    <li>O Projeto Fundo Público e Estado no Brasil Contemporâneo da profa. Giselle Souza da Escola de Serviço Social;</li>
    <li>O Grupo de Trabalho GT-Verbas da Adunirio;</li>
    <li>A Diretoria de Orçamento recentemente fez uma cartilha chamada Entendendo o Orçamento 
        (<a href="http://www.unirio.br/diretoriadeorcamento/entendendo-o-orcamento" target="_blank">
        http://www.unirio.br/diretoriadeorcamento/entendendo-o-orcamento)</a>; e </li>
    <li>Finalmente, não poderia deixar de falar do projeto DATAUNIRIO 
        (<a href="https://dataunirio.github.io/" target="_blank">https://dataunirio.github.io/</a>) que visa 
        disponibilizar informações, dados e metodologias que aumentem a transparência e permitam ampliar o engajamento
        cívico. Entre as muitas iniciativas, está a construção de repositório de dados da Universidade 
        (<a href="http://repositorio.metodosquantitativos.com/" target="_blank">
        http://repositorio.metodosquantitativos.com/</a>).</li>
    </ol> 
      
    <p><b>Profa. Morganna – </b>Para um aluno de pós-graduação interessado em pesquisar transparência orçamentária, o 
    que você aconselharia?</p>

    <p><b>Prof. Steven Dutt-Ross – </b> Acredito que antes da pesquisa sobre transparência, é necessário o entendimento
    sobre o processo orçamentário. Assim, sugiro o estudo das etapas da execução orçamentária (empenho, liquidação, 
    pagamento, restos a pagar, etc) e também o que são as despesas discricionárias e não discricionárias. Depois dessa
    primeira fase, também vale a pena estudar accountability, prestação de contas e orçamento participativo.</p>
    
    <br><br>
    
    <p><b>Referências</b></p>
    <ul>
    <li>LOPES , C. E. A educação estatística no currículo de matemática: um ensaio teórico. Disponível em 
    http://33reuniao.anped.org.br/33encontro/app/webroot/files/file/Trabalhos%20em%20PDF/GT19-6836--Int.pdf Acesso
    em 18/02/2022.</li>
    <li>_________ Fórum Popular do Orçamento do Rio de Janeiro – FPO  De Olho no Orçamento’, Disponível em: 
    https://www.prattein.com.br/home/images/stories/230813/Gestao_Publica/DeOlhoNoOrcamento-ForumPopularRJ.pdf.
    Acesso em 18/02/2022.</li>
    <li>_________ IBASE Material de treinamento para curso de orçamento ‘Prefeito por um dia’, 2003.</li>
    </ul>

    
    <br><br><br>
    <p><img src="./assets/steven.png" style="float:left; margin:10px;"/><b>Steven Dutt-Ross</b> é professor da UNIRIO onde realiza pesquisas sobre a inovação na Gestão Pública, Tecnologia e Métodos Quantitativos. Focado nesses métodos aplicados ao setor público, trabalha com estatística, ciência política, gestão pública, design e economia. Anteriormente, foi pesquisador da Diretoria de Análise de Políticas Públicas - DAPP/FGV e consultor da FGV/Projetos na área de desenvolvimento institucional pela FGV. Também foi Socio-fundador da empresa Sortud pesquisa e consultoria de métodos quantitativos (empresa de pesquisa de opinião e pesquisa de mercado) de 2007 a 2014. Trabalha com análise de dados há 15 anos, com experiência em gestão de projetos e em métodos quantitativos para organizações como Ministério da Educação, Ministério da Justiça, Ministério da Cultura, governo do Estado do Rio de Janeiro, governo do Estado do Rio Grande do Sul e prefeitura da cidade do Rio de Janeiro. Professor Adjunto do Departamento de Métodos Quantitativos da Universidade Federal do Estado do Rio de Janeiro - UNIRIO. Pós-Doutorando em Engenharia Civil pela UFF (2018). Doutor em Engenharia de Produção pela UFF (2012). Mestre em Administração pela Fundação Getúlio Vargas - FGV/RJ (2007). Graduado em Estatística pela Escola Nacional de Ciências Estatísticas - ENCE (2004).</p>

</div>

</template>

<script>
    export default {
        name: "Fevereiro22",
        data: () => ({})
    }
</script>

<style lang="scss" scoped>

</style>
