<template>
    <svg width="487" height="487" viewBox="0 0 487 487" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M298.585 78.035H188.689C149.201 78.035 117.08 110.16 117.08 149.648V259.537C117.08 299.025 149.201 331.15 188.689 331.15H201.452L243.639 408.965L285.818 331.15H298.582C338.073 331.15 370.198 299.025 370.198 259.537V149.648C370.198 110.16 338.073 78.035 298.585 78.035ZM349.906 259.537C349.906 287.834 326.882 310.858 298.582 310.858H273.735L243.639 366.386L213.536 310.858H188.689C160.392 310.858 137.371 287.834 137.371 259.537V149.648C137.371 121.351 160.392 98.3267 188.689 98.3267H298.582C326.882 98.3267 349.906 121.351 349.906 149.648V259.537V259.537Z"
                  fill="black" fill-opacity="0.5"/>
        </g>
        <path d="M209.289 321.983L245.512 390.405L281.735 323.996L299.847 321.983L344.12 307.897L360.219 247.525V134.831L330.033 96.5951L285.76 88.5455H179.103L144.893 102.632L128.793 134.831V285.76C128.793 290.59 148.917 306.555 158.979 313.934L209.289 321.983Z"
              fill="#22436E"/>
        <path d="M298.585 78.035H188.689C149.201 78.035 117.08 110.16 117.08 149.648V259.537C117.08 299.025 149.201 331.15 188.689 331.15H201.452L243.639 408.965L285.818 331.15H298.582C338.073 331.15 370.198 299.025 370.198 259.537V149.648C370.198 110.16 338.073 78.035 298.585 78.035ZM349.906 259.537C349.906 287.834 326.882 310.858 298.582 310.858H273.735L243.639 366.386L213.536 310.858H188.689C160.392 310.858 137.371 287.834 137.371 259.537V149.648C137.371 121.351 160.392 98.3267 188.689 98.3267H298.582C326.882 98.3267 349.906 121.351 349.906 149.648V259.537V259.537Z"
              fill="#FA9F42"/>
        <path d="M151.335 193.508L186.815 214.121V265.212H300.472V214.114L335.946 193.508L243.642 138.812L151.335 193.508ZM207.107 225.907L239.837 244.92H207.107V225.907V225.907ZM280.181 244.92H247.443L280.181 225.904V244.92ZM243.642 223.662L191.435 193.336L243.642 162.401L295.849 193.336L243.642 223.662Z"
              fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "Logo"
    }
</script>

<style>

</style>
