<template>
    <div>
        <h3>
            Créditos
        </h3>
        <ul>
            <li>
                <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a>
                    from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            </li>
            <li>
                investigate by Made from the Noun Project
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "Creditos",
        data: () => ({})
    }
</script>

<style lang="scss" scoped>

</style>